<template>
  <div class="bg-f7f7f7">
    <div class="sticky-payment z-5 d-flex align-center px-4 py-4">
      <div class="w-100">
        <div class="font-weight-bold primaryColor">
          Payments
          <v-progress-circular
            class="ml-2"
            indeterminate
            v-if="loader"
            size="18"
            :width="2"
            color="black"
          ></v-progress-circular>
        </div>
        <div class="pt-3 d-flex">
          <v-slide-group v-model="tabSelect" mandatory>
            <v-slide-item
              v-for="n in tabs"
              :key="n"
              :value="n"
              v-slot="{ active, toggle }"
            >
              <span
                @click="
                  $router
                    .push({ path: 'payment', query: { tab: n } })
                    .catch(() => {})
                "
              >
                <v-btn
                  :title="n"
                  depressed
                  height="26"
                  class="fsize12 text-capitalize mr-2"
                  @click="toggle"
                  :color="active ? 'primary' : 'grey lighten-3'"
                  >{{ n }}</v-btn
                >
              </span>
            </v-slide-item>
          </v-slide-group>
          <v-spacer></v-spacer>
          <!-- <div style="width: 25%">
            <v-select
              dense
              class="mr-3"
              v-model="template"
              placeholder="Filter"
              :items="items"
              outlined
              autofocus
              :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
          </div> -->
        </div>
      </div>
    </div>
    <v-card
      v-if="tabSelect == 'Add Payments'"
      class="ma-4 cardBoxShadow rounded-lg"
    >
      <manual_payments />
    </v-card>
    <suspenseLogsComponent v-if="tabSelect == 'Suspense Logs'" />
    <webhookLogsComponent v-if="tabSelect == 'Webhook Logs'" />
    <failedTransactionsComponent
      v-if="tabSelect == 'Failed Transactions'"
    ></failedTransactionsComponent>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import manual_payments from "./manual_payments.vue";

import suspenseLogsComponent from "./suspenseLogs.vue";
import webhookLogsComponent from "./webhookLogs.vue";
import failedTransactionsComponent from "./failedTransactions.vue";

export default {
  name: "payment",
  data: () => ({
    tabs: [
      "Add Payments",
      "Suspense Logs",
      "Webhook Logs",
      // "JV Logs",
      // "Receipt Logs",
      // "HDFC Logs",
      "Failed Transactions",
      // "Limit File Upload",
    ],
    tabSelect: "",
    search: "",
  }),
  components: {
    manual_payments,

    suspenseLogsComponent,
    failedTransactionsComponent,
    webhookLogsComponent,
  },
  created() {
    this.tabSelect = this.$route.query.tab
      ? this.$route.query.tab
      : "Manual Payments";
  },
  computed: {
    ...mapGetters({
      loader: "getLoader",
    }),
  },
};
</script>
<style>
.sticky-payment {
  position: sticky !important;
  top: 56px !important;
  background-color: #f7f7f7;
}

.z-5 {
  z-index: 5 !important;
}
</style>
