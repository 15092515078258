<template>
  <v-card class="ma-4 cardBoxShadow rounded-lg">
    <div class="px-4 pt-4 w-100 d-flex align-center justify-space-between">
      <v-form
        class="d-flex align-center"
        v-model="formValid"
        ref="form"
        lazy-validation
        @submit.prevent="getWebhookLogs()"
      >
        <div class="pa-4">
          <div>*From Date</div>
          <v-menu
            v-model="dateMenu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                @click:append="dateMenu1 = true"
                class="apiKeyInput"
                v-model="computedDateFormatted1"
                outlined
                persistent-hint
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="fromDateRules"
                placeholder="Select the From Date"
                append-icon="mdi-calendar"
                readonly
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromDate"
              :show-current="true"
              @input="dateMenu1 = false"
              no-title
              :max="new Date().toISOString().substr(0, 10)"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <div class="pa-4">
          <div>*To Date</div>
          <v-menu
            v-model="dateMenu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                @click:append="dateMenu2 = true"
                class="apiKeyInput"
                v-model="computedDateFormatted2"
                outlined
                persistent-hint
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="toDateRules"
                placeholder="Select the To Date"
                append-icon="mdi-calendar"
                readonly
                dense
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              :show-current="true"
              no-title
              @input="dateMenu2 = false"
              :max="new Date().toISOString().substr(0, 10)"
              :min="fromDate"
            >
            </v-date-picker>
          </v-menu>
        </div>

        <div class="px-2 d-flex justify-end mb-2 mt-2">
          <v-btn
            type="submit"
            height="40"
            color="primary"
            depressed
            :loading="loading"
            :disabled="loading"
            class="text-capitalize fsize14"
            >Submit</v-btn
          >
        </div>
      </v-form>
      <v-btn
        v-if="webhookLogs.length > 0"
        height="32"
        @click="downloadUnicodeCSV(webhookLogs, 'webhooklogs')"
        depressed
        min-width="fit-content"
        color="primary"
        class="text-capitalize"
      >
        Download <v-icon class="mx-2 fsize18">mdi-cloud-download </v-icon>
      </v-btn>
    </div>
    <v-data-table
      v-if="webhookLogs.length != 0"
      :headers="headers"
      v-bind:items="webhookLogs"
      :search="search"
      :value="webhookLogs"
      class="my-2"
      id="table"
      item-key="name"
      single-select
    >
      <template v-slot:item="props">
        <tr>
          <td>{{ props.item.date ? props.item.date?.split(" ")[0] : "" }}</td>
          <td>
            {{ "HDFC" }}
          </td>
          <td class="text-center">{{ props.item.name }}</td>
          <td class="text-right">{{ props.item.accountNo }}</td>
          <td class="text-right">
            {{ props.item.amount ? Number(props.item.amount).toFixed(2) : "" }}
          </td>
          <td class="text-right">{{ props.item.bankName }}</td>

          <td class="text-right">
            <v-btn
              height="24"
              width="80"
              depressed
              :color="
                props.item.paymentStatus == 'Success' ? '#62ab74' : '#da4e4e'
              "
              class="text-capitalize fsize12 white--text ml-2"
            >
              {{ props.item.paymentStatus }}
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div
      v-else-if="!loading && fromDate != null && toDate != null"
      class="d-flex align-center justify-center min-height-100"
    >
      No Records Found
    </div>
  </v-card>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import commonFunctions from "../../mixins/commonFunctions";
export default {
  data: () => ({
    formValid: true,
    dateMenu1: false,
    dateMenu2: false,
    fromDate: null,
    toDate: null,
    webhookSource: "",

    fromDateRules: [(v) => !!v || "From Date is required"],
    toDateRules: [(v) => !!v || "To Date is required"],
    paymentMethod: "",
    userId: "",
    headers: [
      {
        text: "Date",
        align: "left",
        value: "transactionDate",
        sortable: false,
      },
      {
        text: "Source",
        align: "left",
        value: "source",

        sortable: false,
      },
      {
        text: "Name",
        align: "center",
        value: "source",

        sortable: false,
      },
      {
        text: "Account No",
        align: "right",
        value: "remitterAccount",
        sortable: false,
      },

      {
        text: "Amount",
        align: "right",
        value: "amount",
        sortable: false,
      },
      {
        text: "Client Bank Name",
        align: "right",
        value: "mnemonicCode",
        sortable: false,
      },
      {
        text: "Payment Status",
        align: "right",
        value: "",
        sortable: false,
      },
    ],
    search: "",
  }),
  mixins: [commonFunctions],
  methods: {
    getWebhookLogs() {
      if (this.fromDate && this.toDate) {
        let json = {
          fromDate: this.fromDate,

          toDate: this.toDate,

          userId: this.userId,

          paymentMethod: this.paymentMethod
            ? this.paymentMethod.toUpperCase()
            : "",
        };
        this.$store.dispatch("payments/getWebHookLogs", json);
      } else {
        this.$store.commit("SET_SNAKBAR", {
          msg: "From date and Two date cannot be empty",
          show: true,
          color: "red lighten-1",
          timeout: 2500,
        });
      }
    },
    getMaxToDate() {
      let currentDate = new Date();
      let maxDate = new Date(this.fromDate);
      maxDate.setDate(maxDate.getDate() + 6); // Add 7 days
      if (maxDate > currentDate) {
        return currentDate.toISOString().substr(0, 10);
      } else {
        return maxDate.toISOString().substr(0, 10);
      }
    },
  },
  created() {
    this.$store.commit("payments/setWebhookLogs", []);
  },
  computed: {
    ...mapState("payments", ["webhookLogs"]),
    ...mapGetters({
      loading: "getLoader",
    }),
    computedDateFormatted1: {
      get() {
        return this.VutifyFormatDate(this.fromDate, '-');
      },
      set() {},
    },
    computedDateFormatted2: {
      get() {
        return this.VutifyFormatDate(this.toDate,'-');
      },
      set() {},
    },
  },
};
</script>
