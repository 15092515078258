<template>
  <div>
    <div v-if="!isOrderPushRequest">
      <div>
        <div class="px-4 pt-4">
          <v-btn height="20" class="fsize14" rounded color="green" dark>
            Receipt Payments
          </v-btn>
        </div>
      </div>
      <div class="px-4 pt-4 w-100 d-flex align-center justify-space-between">
        <v-form
          v-if="!isOrderPushRequest"
          class="d-flex align-center"
          v-model="formValid"
          ref="form"
          lazy-validation
          @submit.prevent="getUserBankDetails()"
        >
          <div class="px-2 fsize14">
            <div class="pb-1">User ID</div>
            <v-text-field
              outlined
              dense
              placeholder="Enter the UserId"
              v-model="userID"
              :rules="userIdRules"
            ></v-text-field>
          </div>

          <div class="px-2 d-flex justify-end mb-2 mt-2">
            <v-btn
              type="submit"
              :loading="userBankDetailsLoader"
              :disabled="userBankDetailsLoader"
              height="40"
              color="primary"
              depressed
              class="text-capitalize fsize14"
              >Add Payment</v-btn
            >
          </div>
        </v-form>
      </div>
      <!-- <div>
        <div class="px-4 pt-4">
          <v-btn height="20" class="fsize14" rounded color="green" dark>
            JV Payments
          </v-btn>
        </div>
      </div> -->
      <!-- <div class="px-4 pt-4 w-100 d-flex align-center justify-space-between">
        <v-form
          v-if="!isOrderPushRequest"
          class="d-flex align-center"
          v-model="formValid"
          ref="form2"
          lazy-validation
          @submit.prevent="sendJvPayments()"
        >
          <div class="px-2 fsize14">
            <div class="pb-1">Account Code</div>
            <v-text-field
              outlined
              dense
              placeholder="Enter the Account code"
              v-model="accountCode"
              :rules="accountCodeRules"
            ></v-text-field>
          </div>
          <div class="px-2 fsize14">
            <div class="pb-1">Amount</div>
            <v-text-field
              outlined
              dense
              @keypress="keyPressNumeric($event)"
              placeholder="Enter the Amount"
              v-model="amount"
              :rules="amountRules"
            ></v-text-field>
          </div>
          <div class="px-2 fsize14">
            <div class="pb-1">Bill No</div>
            <v-text-field
              outlined
              dense
              placeholder="Enter the Bill No"
              v-model="billNo"
              :rules="billNoRules"
            ></v-text-field>
          </div>
          <div class="px-2 fsize14">
            <div class="pb-1">Client Acc No.</div>
            <v-text-field
              outlined
              dense
              placeholder="Enter the Client Acc No."
              v-model="clientAccNo"
              :rules="clientNoRules"
            ></v-text-field>
          </div>
          <div class="px-2 fsize14">
            <div class="pb-1">COCD</div>
            <v-select
              outlined
              dense
              height="40"
              v-model="cocd"
              :items="cocdList"
              :menu-props="{ bottom: true, offsetY: true }"
              :rules="cocdRules"
            ></v-select>
          </div>
          <div class="px-2 fsize14">
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="pb-1">Voucher Date</div>
                <v-text-field
                  @click:append="dateMenu = true"
                  v-model="computedVoucherDate"
                  outlined
                  persistent-hint
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="voucherDateRules"
                  placeholder="Enter the Voucher date"
                  append-icon="mdi-calendar"
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="voucherDate"
                :show-current="true"
                no-title
                @input="dateMenu = false"
              ></v-date-picker>
            </v-menu>
          </div>

          <div class="px-2 d-flex justify-end mb-2 mt-2">
            <v-btn
              type="submit"
              :loading="insertJvEntryLoader"
              :disabled="insertJvEntryLoader"
              height="40"
              color="primary"
              depressed
              class="text-capitalize fsize14"
              >Add Payment</v-btn
            >
          </div>
        </v-form>
      </div> -->
    </div>

    <div class="pt-4" v-if="isOrderPushRequest">
      <OrderPushRequest></OrderPushRequest>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import OrderPushRequest from "./orderPushRequest.vue";
import commonFunctions from '../../mixins/commonFunctions'
export default {
  name: "ManualPayments",
  data: () => ({
    userID: "",
    paymentData: "",
    getLoader: false,
    formValid: true,
    accountCode: "",
    accountCodeRules: [(v) => !!v || "Account Code is required"],
    amount: "",
    billNo: "",
    cocdList: [
      "NSE_FNO",
      "NSE_CASH",
      "BSE_CASH",
      "BSE_FNO",
      "MCX",
      "CD_NSE",
      "CD_BSE",
    ],
    clientAccNo: "",
    clientNoRules: [(v) => !!v || "Client Account Number is required"],
    billNoRules: [(v) => !!v || "Bill Number is required"],
    amountRules: [(v) => !!v || "Amount is required"],
    userIdRules: [(v) => !!v || "userID is required"],
    cocd: "NSE_FNO",
    dateMenu: false,
    voucherDate: null,
    voucherDateRules: [(v) => !!v || "Voucher Date  is required"],
    cocdRules: [(v) => !!v || "COCD is required"],
    headers: [
      { text: "UserName", value: "userName", align: "start", sortable: true },
      { text: "E-mail", value: "email", sortable: false },
      { text: "Phone", value: "phone", sortable: false },
      { text: "Client Name", value: "clientName", sortable: false },
      { text: "Account Number", value: "bankActNo", sortable: false },
      { text: "Bank Code", value: "bankCode", sortable: false },
      { text: "Make Payment", value: "", sortable: false },
      //   { text: "Status", value: "status", sortable: false },
      //   { text: "Organization", value: "parentOrg", sortable: false },
    ],
  }),
  mixins:[commonFunctions],
  computed: {
    ...mapState("payments", [
      "userBankDetails",
      "isOrderPushRequest",
      "userBankDetailsLoader",
      "insertJvEntryLoader",
    ]),
    computedVoucherDate() {
      return this.VutifyFormatDate(this.voucherDate,'/');
    },
  },
  components: {
    OrderPushRequest,
  },

  methods: {
    getUserBankDetails() {
      if (this.$refs.form.validate()) {
        let userId = this.userID;
        this.$store
          .dispatch("payments/getUserBankDetails", { userId })
          .finally(() => {
            this.userID = "";
          });
      } else {
        this.$store.commit("SET_SNAKBAR", {
          msg: "Invalid Input",
          show: true,
          color: "red darken-1",
          timeout: 2500,
        });
      }
    },
    keyPressNumeric(event) {
      var inp = String.fromCharCode(event.keyCode);

      if (event.keyCode != 13) {
        if (
          /[0-9.]/.test(inp) &&
          (event.key !== "." || !this.amount.includes("."))
        ) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    },
    sendJvPayments() {
      if (this.$refs.form2.validate()) {
        let json = {
          accountCode: this.accountCode,
          amount: this.amount,
          billNo: this.billNo,
          cocd: this.cocd,
          narration: "Being Amont Received",
          vocherDate: this.computedVoucherDate,
          cnAccountCode: this.clientAccNo,
          withGST: "N",
        };
        this.$store.dispatch("payments/insertJvEntry", json);
      } else {
        this.$store.commit("SET_SNAKBAR", {
          msg: "Invalid Input",
          show: true,
          color: "red darken-1",
          timeout: 2500,
        });
      }
    }
  },
  created() {
    this.$store.commit("payments/setOrderPushRequest", false);
  },
};
</script>
