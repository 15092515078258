<template>
  <v-card class="ma-4 cardBoxShadow rounded-lg">
    <div class="px-4 pt-4 w-100 d-flex align-center justify-between">
      <v-form
          class="d-flex align-center"
          v-model="formValid"
          ref="form"
          lazy-validation
          @submit.prevent="getFailedLogs()"
        >
          <div class="pa-4">
            <div>* Date</div>
            <v-menu
              v-model="dateMenu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  @click:append="dateMenu1 = true"
                  class="apiKeyInput"
                  v-model="computedDateFormatted1"
                  outlined
                  persistent-hint
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="fromDateRules"
                  placeholder="Select the From Date"
                  append-icon="mdi-calendar"
                  readonly
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                :show-current="true"
                no-title
                @input="dateMenu1 = false"
                :max="new Date().toISOString().substr(0, 10)"
              >
              </v-date-picker>
            </v-menu>
          </div>
  
          <div class="px-2 d-flex justify-end mb-2 mt-2">
            <v-btn
              type="submit"
              height="40"
              color="primary"
              depressed
              :loading="loader"
              class="text-capitalize fsize14"
              >Submit</v-btn
            >
          </div>
        </v-form>
      <div v-if="failedTransactionLogs.length > 0" class="pr-4 pl-0 mt-2">
        <v-btn
          height="32"
          @click="
            downloadUnicodeCSV(failedTransactionLogs, 'failed-transactions')
          "
          depressed
          min-width="fit-content"
          color="primary"
          class="text-capitalize"
        >
          Download <v-icon class="mx-2 fsize18">mdi-cloud-download </v-icon>
        </v-btn>
      </div>
    </div>
    <v-data-table
      v-if="failedTransactionLogs.length != 0"
      :headers="headers"
      v-bind:items="failedTransactionLogs"
      :search="search"
      :value="failedTransactionLogs"
      class="my-2"
      id="table"
      item-key="name"
      single-select
    >
      <template v-slot:item="props">
        <tr>
          <td>{{ props.index + 1 }}</td>
          <td class="text-center">
            {{
              props.item?.transactionDate?.split(" ")[0]
                ? props.item?.transactionDate?.split(" ")[0]
                : "-"
            }}
          </td>
          <td class="text-left">{{ props.item.remitterName }}</td>
          <td class="text-right">{{ props.item.remitterAccount }}</td>
          <td class="text-right">{{ props.item.remitterBank }}</td>
          <td class="text-right">
            {{ props.item.amount ? Number(props.item.amount).toFixed(2) : "" }}
          </td>
          <td class="text-center">{{ props.item.mnemonicCode }}</td>

          <td class="d-flex justify-center align-center">
            <v-btn
              v-if="!props.item.isAllocate"
              @click="props.item.isAllocate = true"
              height="24"
              width="80"
              depressed
              :color="'#808080'"
              class="text-capitalize fsize12 white--text ml-2"
            >
              <!-- {{ i.icclStatus == "1" ? "Success" : "Failed" }} -->
              Allocate
            </v-btn>

            <input
              v-if="props.item.isAllocate"
              v-model="props.item.allocateUserId"
              class="tableUserInput py-2"
              placeholder="User Id"
            />
            <v-btn
              v-if="props.item.isAllocate && props.item.allocateUserId != ''"
              height="24"
              @click="
                handleUpdateClick(props.item), (props.item.isAllocate = false)
              "
              width="80"
              depressed
              :color="'#808080'"
              class="text-capitalize fsize12 white--text ml-2"
            >
              Update
            </v-btn>
            <v-btn
              v-if="props.item.isAllocate && props.item.allocateUserId == ''"
              height="24"
              @click="
                (props.item.isAllocate = false)
              "
              width="80"
              depressed
              :color="'#808080'"
              class="text-capitalize fsize12 white--text ml-2"
            >
              Cancel
            </v-btn>
            <v-btn
              v-if="!props.item.isAllocate"
              height="24"
              @click="handleSuspenseClick(props.item)"
              width="80"
              depressed
              :color="'#808080'"
              class="text-capitalize fsize12 white--text ml-2"
            >
              Suspense
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div
      v-else-if="!loader"
      class="d-flex align-center justify-center min-height-100"
    >
      No Records Found
    </div>
  </v-card>
</template>
<script>
import { mapState } from "vuex/dist/vuex.common.js";
import commonFunctions from "../../mixins/commonFunctions";
export default {
  data: () => ({
    formValid: true,
    dateMenu1: false,
    fromDate: null,
    search: "",
    fromDateRules: [(v) => !!v || "From Date is required"],
    headers: [
      { text: "S.NO", align: "start", sortable: false },
      {
        text: "Date",
        align: "center",
        value: "transactionDate",
        sortable: true,
      },
      {
        text: "Name",
        align: "left",
        value: "remitterName",

        sortable: true,
      },
      {
        text: "Account No",
        align: "right",
        value: "remitterAccount",
        sortable: true,
      },
      {
        text: "Bank Name",
        align: "right",
        value: "remitterBank",
        sortable: true,
      },
      {
        text: "Amount",
        align: "right",
        value: "amount",
        sortable: true,
      },
      {
        text: "Status",
        align: "center",
        value: "mnemonicCode",
        sortable: true,
      },
      {
        text: "Allocate / Suspense",
        align: "center",
        value: "",
        sortable: false,
      },
    ],
  }),
  methods: {
    handleUpdateClick(value) {
      let json = {
        userId: value.allocateUserId,
        amount: value.amount,
        remitterName: value.remitterName,
        alertSequenceNo: value.alertSequenceNo,
        remitterAcc: value.remitterAccount,
        remitterIfsc: "",
        chequeNo: "",
        userRefNum: "",
        transactionDescription: "",
        transactionDate: "",
        fromSuspense: "false",
      };

      this.$store.dispatch("payments/allocateFailedHdfcLogs", {
        json: json,
        from: "failed",
      });
    },
    handleSuspenseClick(value) {
      let json = {
        alertSequenceNo: value.alertSequenceNo,
      };
      this.$store.dispatch(`payments/suspenseFailedHdfcLogs`, json);
    },
    getFailedLogs(){
      let json = {
        date: this.fromDate
      }
      this.$store.dispatch("payments/getFailedHdfcLogs",json);
    }
  },
  mixins: [commonFunctions],
  computed: {
    ...mapState("payments", ["failedTransactionLogs"]),
    ...mapState(['loader']),
    computedDateFormatted1: {
      get() {
        return this.VutifyFormatDate(this.fromDate,'-');
      },
      set() {},
    }
  },
  created() {
    this.fromDate = new Date().toISOString().substr(0, 10);
   this.getFailedLogs()
  },
};
</script>
<style>
.tableUserInput {
  width: 80px;
  max-height: 24px;
  border-radius: 5px; /* Adjust the value to make the border more or less rounded */
  border: 2px solid #ccc; /* Optional: Define the border color and thickness */
  padding: 10px; /* Optional: Add some padding inside the input */
  margin-left: 8px;
}
</style>
